import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Register.module.scss";

const Register = () => {
  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const onSubmit = (values) => {
    const newUser = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      passwordConfirm: values.confirmPassword,
    };
    axios
      .post("https://api.cerebeye.io/auth/register", newUser)
      .then((res) => {
        navigate("/login");
        alert("User registered successfully");
      })
      .catch((err) => {
        console.log(err.response.data.message);
        alert(err.response.data.message);
      });
  };

  return (
    <div className={styles.registerContainer}>
      <div className={styles.registerForm}>
        <h1>Register</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={styles.inputs}>
              <div className='formGroup'>
                <label htmlFor='firstName'>First Name</label>
                <Field
                  type='text'
                  id='firstName'
                  name='firstName'
                  className={`form-control ${styles.formControl} ${styles.isInvalid}`}
                />
                <ErrorMessage
                  name='firstName'
                  component='div'
                  className={`invalid-feedback ${styles.invalidFeedback}`}
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='lastName'>Last Name</label>
                <Field
                  type='text'
                  id='lastName'
                  name='lastName'
                  className={`form-control ${styles.formControl} ${styles.isInvalid}`}
                />
                <ErrorMessage
                  name='lastName'
                  component='div'
                  className={`invalid-feedback ${styles.invalidFeedback}`}
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='email'>Email</label>
                <Field
                  type='email'
                  id='email'
                  name='email'
                  className={`form-control ${styles.formControl} ${styles.isInvalid}`}
                />
                <ErrorMessage
                  name='email'
                  component='div'
                  className={`invalid-feedback ${styles.invalidFeedback}`}
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='password'>Password</label>
                <Field
                  type='password'
                  id='password'
                  name='password'
                  className={`form-control ${styles.formControl} ${styles.isInvalid}`}
                />
                <ErrorMessage
                  name='password'
                  component='div'
                  className={`invalid-feedback ${styles.invalidFeedback}`}
                />
              </div>
              <div className='formGroup'>
                <label htmlFor='confirmPassword'>Confirm Password</label>
                <Field
                  type='password'
                  id='confirmPassword'
                  name='confirmPassword'
                  className={`form-control ${styles.formControl} ${styles.isInvalid}`}
                />
                <ErrorMessage
                  name='confirmPassword'
                  component='div'
                  className={`invalid-feedback ${styles.invalidFeedback}`}
                />
              </div>
              <button
                type='submit'
                className={`btn btn-primary ${styles.btnPrimary}`}
              >
                Register
              </button>
              <Link to='/login' className={styles.loginLink}>
                Login
              </Link>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Register;
