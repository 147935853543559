import { createSlice } from "@reduxjs/toolkit";

// Create a slice for auth

const initialAuthState = {
  isAuthenticated: false,
  token: null,
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  error: null,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    // Auth Actions---------------------------------------------
    signUp: (state, action) => {
      state.loading = true;
    },
    signUpSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    signUpFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    signIn: (state, action) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.userId = action.payload.userId;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    signInFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    signOut: (state, action) => {
      state.isAuthenticated = false;
      state.token = null;
      state.userId = null;
      state.firstName = null;
      state.lastName = null;
      state.email = null;
    },
  },
});

export const authActions = authSlice.actions; // for dispatching the actions in dispath() method that useDispatch() hook returns

export default authSlice.reducer; // for adding the reducer to the store
