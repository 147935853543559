import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { authActions } from "../../shared/store/slices/auth-slice";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./Login.module.scss";

const Login = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (Cookies.get("access_token") || isAuthenticated) {
      navigate("/dashboard/products");
    }
  }, [isAuthenticated, navigate]);

  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      axios
        .post("https://api.cerebeye.io/auth/login", formData)
        .then((res) => {
          const userResponse = {
            token: res.data.data?.access_token,
            userId: res.data.data?.user.id,
            firstName: res.data.data?.user.firstName,
            lastName: res.data.data?.user.lastName,
            email: res.data.data?.user.email,
          };

          dispatch(authActions.signInSuccess(userResponse));
          localStorage.setItem("user", JSON.stringify(userResponse));
          Cookies.set("access_token", userResponse.token);

          navigate("/dashboard/products");
        })
        .catch((err) => {
          console.log("Ekin");
          alert(err.response.data.message);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginForm}>
        <h1>Login</h1>
        <form className={styles.inputs} onSubmit={handleSubmit}>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='text'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              className={`form-control ${styles.formControl} ${
                errors.email ? styles.isInvalid : ""
              }`}
            />

            {errors.email && (
              <p className={styles.invalidFeedback}>{errors.email}</p>
            )}
          </div>
          <div className='form-group position-relative'>
            <label htmlFor='password'>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              id='password'
              name='password'
              value={formData.password}
              onChange={handleChange}
              className={`form-control ${styles.formControl} ${
                errors.password ? styles.isInvalid : ""
              }`}
            />
            {errors.password && (
              <div className={`invalid-feedback ${styles.invalidFeedback}`}>
                {errors.password}
              </div>
            )}
            <button
              type='button'
              className={`btn btn-outline-secondary ${styles.showPasswordToggle}`}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          <div>
            <button
              type='submit'
              className={`btn btn-primary ${styles.btnPrimary}`}
              disabled={isSubmitting}
            >
              Login
            </button>
            <Link to='/register' className={styles.registerLink}>
              Register
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
