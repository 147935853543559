import React, { useEffect, useState } from "react";
import { fetchCurrentUser } from "../services/product";
import { ToastContainer, toast } from "react-toastify";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./UserInformations.module.scss"; // Assuming you have a CSS module file for custom styles.

const UserInformation = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCurrentUser()
      .then((result) => {
        setUser(result.data.user);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Kullanıcı verileri alınırken hata oluştu");
        setLoading(false);
      });
  }, []);

  return (
    <Container className={`mt-5 min-vh-100 ${styles.userInformation}`}>
      <h1 className='text-center mb-5'>Kullanıcıyı Yönet</h1>
      {loading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: "50vh" }}
        >
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Yükleniyor...</span>
          </Spinner>
        </div>
      ) : (
        user && (
          <Card className={`shadow-lg rounded ${styles.userCard}`}>
            <Card.Header className='bg-primary text-white text-center'>
              <div className='d-flex align-items-center justify-content-center'>
                <div>
                  <i className='bi bi-person-circle me-3'></i>{" "}
                  {/* Bootstrap ikonu */}
                </div>
                <div>
                  <h2 className='mb-0'>
                    {user.firstName} {user.lastName}
                  </h2>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row className='mb-4'>
                <Col md={4} className='text-center'>
                  {/* Profil Resmi için Yer Tutucu */}
                  <img
                    src='https://via.placeholder.com/150'
                    alt='Kullanıcı Profili'
                    className='rounded-circle'
                  />
                  <h5 className='mt-3'>
                    {user.firstName} {user.lastName}
                  </h5>
                </Col>
                <Col md={8}>
                  <Row className='mb-2'>
                    <Col sm={6}>
                      <strong>Email:</strong> {user.email}
                    </Col>
                    <Col sm={6}>
                      <strong>Mobil Telefon:</strong> (sağlanmadı)
                    </Col>
                  </Row>
                  <Row className='mb-2'></Row>
                  <Row className='mb-2'>
                    <Col sm={6}>
                      <strong>Oluşturulma:</strong>{" "}
                      {new Date(user.createdAt).toLocaleDateString()}
                    </Col>
                  </Row>
                  <Row className='mb-2'></Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )
      )}

      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default UserInformation;
