import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Cookies from "js-cookie";
import DashboardPage from "./pages/DashboardPage";
import UserInformation from "./pages/UserInformations";
import Products from "./pages/Products";
import Orders from "./pages/Orders";

const PrivateRoute = ({ element: Element, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const token = Cookies.get("access_token");

  return token ? <Element {...rest} /> : <Navigate to="/login" />;
};

const App = () => {
  const token = Cookies.get("access_token");

  useEffect(() => {
    if (token) {
      // Set user as authenticated
      // dispatch(authActions.signInSuccess());
    }
  }, [token]);

  return (
    <>
      {/* If user is authenticated and tries to access login or register page, redirect to dashboard */}
      {/* If user is not authenticated and tries to access dashboard, redirect to login */}
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard/products" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={DashboardPage} />}
        >
          <Route path="products" element={<Products />} />
          <Route path="orders" element={<Orders />} />
          <Route path="user-information" element={<UserInformation />} />
        </Route>
      </Routes>

      {/* <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route
          path='/dashboard'
          element={<PrivateRoute element={DashboardPage} />}
        >
          <Route path='products' element={<Products />} />
          <Route path='orders' element={<Orders />} />
          <Route path='user-information' element={<UserInformation />} />
        </Route>
      </Routes> */}
    </>
  );
};

export default App;
