import axios from "axios";
import Cookies from "js-cookie";

// Define the base URL
const BASE_URL = process.env.REACT_APP_API_URL;

// Create an axios instance
const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  },
});

// Fetch current user data
const fetchCurrentUser = () => {
  return apiClient.get("/users/me");
};

// Fetch products
const fetchProducts = () => {
  return apiClient.get("/products");
};

// Add a new product
const addProduct = (product) => {
  return apiClient.post("/products", product);
};

// Update a product
const updateProduct = (id, product) => {
  return apiClient.put(`/products/${id}`, product);
};

// Delete a product
const deleteProduct = (id) => {
  return apiClient.delete(`/products/${id}`);
};

export {
  fetchCurrentUser,
  fetchProducts,
  addProduct,
  updateProduct,
  deleteProduct,
};
