import React from "react";
import { useEffect, useState } from "react";
import classes from "./AddModal.module.scss";

const AddModal = ({ onSave, onClose }) => {
  // Yeni etkinlik eklemek için modal içeriği
  const [productToAdd, setProductToAdd] = useState({
    productName: null,
    price: null,
    productDescription: null,
    skinType: null,
  });

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(productToAdd);
    onSave(productToAdd);
    onClose();
  };

  useEffect(() => {}, [productToAdd]);

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className='form-group m-3'>
          <input
            className='form-control'
            type='text'
            placeholder='Product Name'
            onChange={(e) =>
              setProductToAdd({ ...productToAdd, productName: e.target.value })
            }
          />
        </div>
        <div className='form-group m-3'>
          <input
            className='form-control'
            type='number'
            placeholder='Price'
            onChange={(e) =>
              setProductToAdd({
                ...productToAdd,
                price: Number(e.target.value),
              })
            }
          />
        </div>
        <div className='form-group  m-3'>
          <input
            className='form-control'
            type='text'
            placeholder='Product Description'
            onChange={(e) =>
              setProductToAdd({
                ...productToAdd,
                productDescription: e.target.value,
              })
            }
          />
        </div>
        <div className='form-group m-3'>
          <select
            className='form-control'
            onChange={(e) => {
              setProductToAdd({ ...productToAdd, skinType: e.target.value });
            }}
          >
            <option value='normal'>Normal</option>
            <option value='dry'>Dry</option>
            <option value='oily'>Oily</option>
            <option value='ombination'>Combination</option>
          </select>
        </div>
        <div className='w-100 d-flex justify-content-between'>
          <button type='button' className='btn btn-danger' onClick={onClose}>
            Close
          </button>
          <button type='submit' className='btn btn-primary'>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddModal;
