import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../../shared/store/slices/auth-slice";
import Cookies from "js-cookie";
import { Dropdown } from "react-bootstrap";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(authActions.signOut());
    localStorage.removeItem("user");
    Cookies.remove("access_token");
    navigate("/login");
  };

  const navigateToUserInfo = () => {
    navigate("/dashboard/user-information");
  };
  return (
    <nav
      className='navbar navbar-expand-lg navbar-light mb-5'
      style={{
        backgroundColor: "#F8F8F8",
      }}
    >
      <div className=' mx-3 collapse navbar-collapse justify-content-end'>
        <ul className='navbar-nav ml-auto'>
          <Dropdown>
            <Dropdown.Toggle variant='light' id='dropdown-basic'>
              <FontAwesomeIcon icon={faUser} size='lg' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={navigateToUserInfo}>
                Kullanıcı Bilgileri
              </Dropdown.Item>
              <Dropdown.Item onClick={logoutHandler}>Çıkış Yap</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
