import React from "react";
import classes from "./Modal.module.scss";
import AddModal from "./AddModal/AddModal";
import UpdateModal from "./UpdateModal.js/UpdateModal";

const Modal = ({ isOpen, onSave, onClose, mode, selectedProduct }) => {
  let content = null;

  if (mode === "add") {
    content = (
      <AddModal
        onSave={(productToAdd) => {
          onSave(productToAdd);
        }}
        onClose={onClose}
      />
    );
  } else if (mode === "update") {
    content = (
      <UpdateModal
        onSave={(productToAdd) => {
          onSave(productToAdd);
        }}
        onClose={onClose}
        selectedProduct={selectedProduct}
      />
    );
  }

  return (
    <div className={`${classes.modal} ${isOpen ? classes.show : ""}`}>
      <div
        className={`${classes.overlay} ${isOpen ? classes.show : ""}`}
        onClick={onClose}
      ></div>
      <div className={classes.modalContent}>
        <span className={classes.close} onClick={onClose}>
          &times;
        </span>
        {content}
      </div>
    </div>
  );
};

export default Modal;
