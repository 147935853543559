import React, { useEffect, useState } from "react";
import { fetchOrders } from "../services/orders";
import { ToastContainer, toast } from "react-toastify";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./Orders.module.scss"; // Assuming you have a CSS module file for custom styles.

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrders()
      .then((result) => {
        setOrders(result.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Sipariş verileri alınırken hata oluştu");
        setLoading(false);
      });
  }, []);

  return (
    <Container className={`mt-5 min-vh-100 ${styles.orders}`}>
      <h1 className='text-center mb-5'>Siparişleri Yönet</h1>
      {loading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ minHeight: "50vh" }}
        >
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Yükleniyor...</span>
          </Spinner>
        </div>
      ) : orders.length === 0 ? (
        <p className='text-center'>Henüz sipariş yok.</p>
      ) : (
        orders.map((order) => (
          <Card
            key={order.id}
            className={`mb-4 shadow-lg rounded ${styles.orderCard}`}
          >
            <Card.Header className='bg-primary text-white text-center'>
              <h2 className='mb-0'>Sipariş ID: {order.id}</h2>
            </Card.Header>
            <Card.Body>
              <Row className='mb-2'>
                <Col sm={6}>
                  <strong>Kullanıcı:</strong> {order.user.firstName}{" "}
                  {order.user.lastName}
                </Col>
                <Col sm={6}>
                  <strong>Email:</strong> {order.user.email}
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col sm={6}>
                  <strong>Fiyat:</strong> ${order.prescription.price}
                </Col>
                <Col sm={6}>
                  <strong>Durum:</strong> {order.status}
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col sm={12}>
                  <strong>Ürünler:</strong>
                  {order.prescription.plants.map((plant) => (
                    <div key={plant.id}>
                      {plant.plantName} ({plant.latinName}) - {plant.function}
                    </div>
                  ))}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))
      )}

      <ToastContainer
        position='bottom-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default Orders;
