import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast, ToastContainer } from "react-toastify";
import { Dropdown, Pagination, Spinner } from "react-bootstrap";
import Modal from "../components/auth/UI/Modal/Modal";
import {
  fetchProducts,
  addProduct,
  updateProduct,
  deleteProduct,
} from "../services/product";
import styles from "./Products.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSkinType, setSelectedSkinType] = useState("normal");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [modalState, setModalState] = useState({
    isOpen: false,
    mode: "",
    product: null,
  });

  useEffect(() => {
    fetchProducts()
      .then((result) => {
        setProducts(result.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  const handleDeleteProduct = (productId) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteProduct(productId)
              .then(() => {
                toast.success("Product deleted successfully");
                setProducts((prevProducts) =>
                  prevProducts.filter((product) => product.id !== productId)
                );
              })
              .catch(() => toast.error("Error deleting product"));
          },
        },
        { label: "No" },
      ],
    });
  };

  const handleModalSave = (product) => {
    if (modalState.mode === "add") {
      handleAddProduct(product);
    } else if (modalState.mode === "update") {
      handleUpdateProduct(product);
    }
  };

  const handleAddProduct = (product) => {
    addProduct(product)
      .then((res) => {
        toast.success("Product added successfully");
        setProducts((prevProducts) => [...prevProducts, res.data.product]);
        setModalState({ isOpen: false, mode: "", product: null });
      })
      .catch(() => {
        toast.error("Error adding product");
        setModalState({ isOpen: false, mode: "", product: null });
      });
  };

  const handleUpdateProduct = (product) => {
    updateProduct(product.id, product)
      .then((res) => {
        toast.success("Product updated successfully");
        setProducts((prevProducts) =>
          prevProducts.map((prevProduct) =>
            prevProduct.id === product.id ? res.data.product : prevProduct
          )
        );
        setModalState({ isOpen: false, mode: "", product: null });
      })
      .catch(() => {
        toast.error("Error updating product");
        setModalState({ isOpen: false, mode: "", product: null });
      });
  };

  // Calculate product statistics
  const totalProducts = products.length;
  const totalPrice = products
    .reduce((acc, product) => acc + product.price, 0)
    .toFixed(2);

  // Count products by skin type
  const productCountBySkinType = products.reduce((acc, product) => {
    acc[product.skinType] = (acc[product.skinType] || 0) + 1;
    return acc;
  }, {});

  const handleSkinTypeChange = (skinType) => {
    setSelectedSkinType(skinType);
  };

  // Pagination logic
  const totalPages = Math.ceil(totalProducts / itemsPerPage);
  const currentItems = products.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div style={{ minHeight: "100vh" }}>
      <div className='container' style={{ minHeight: "100%" }}>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: "100vh" }}
          >
            <Spinner animation='border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className='row'>
              {/* Total Products Card */}
              <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                <div className={`card ${styles.card}`}>
                  <div className='card-body'>
                    <h5 className='card-text'>{totalProducts}</h5>
                    <p className='card-title'>Toplam Ürün Sayısı</p>
                  </div>
                </div>
              </div>
              {/* Total Price Card */}
              <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                <div className={`card ${styles.card}`}>
                  <div className='card-body'>
                    <h5 className='card-text'>{totalPrice} TL</h5>
                    <p className='card-title'>Toplam Fiyat</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                <div className={`card ${styles.card}`}>
                  <div className='card-body p-3'>
                    <div className='card-title mb-1 d-flex justify-content-between align-items-center'>
                      Cilt Tipi: {selectedSkinType}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='secondary'
                          id='dropdown-basic'
                          style={{
                            backgroundColor: "transparent",
                            color: "#000",
                            border: "none",
                          }}
                        >
                          Seç
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {["dry", "combination", "normal", "oily"].map(
                            (skinType) => (
                              <Dropdown.Item
                                key={skinType}
                                onClick={() => handleSkinTypeChange(skinType)}
                              >
                                {skinType}
                              </Dropdown.Item>
                            )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <h5 className='card-text'>
                      {selectedSkinType
                        ? productCountBySkinType[selectedSkinType] || 0
                        : "Cilt Tipi Seçiniz"}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between mb-3'>
              <h2>Ürünler</h2>
              <button
                className='btn btn-outline-success'
                onClick={() =>
                  setModalState({ isOpen: true, mode: "add", product: null })
                }
              >
                Ürün Ekle
              </button>
            </div>
            <div style={{ overflowY: "auto" }}>
              <div className='card-body m-4'>
                {/* Product List */}
                <div className='row'>
                  {currentItems.map((product) => (
                    <div
                      key={product.id}
                      className='col-lg-4 col-md-6 col-sm-12 mb-4'
                    >
                      <div className={`card h-100 ${styles.card}`}>
                        <div className='card-body'>
                          <h3 className='card-title'>{product.productName}</h3>
                          <p className='card-text'>
                            {product.productDescription}
                          </p>
                          <p className='card-text'>Fiyat: {product.price} TL</p>
                          <p className='card-text'>
                            Cilt Tipi: <strong>{product.skinType}</strong>
                          </p>
                          <p className='card-text'>
                            Stok:{" "}
                            {product.stock > 0 ? (
                              <strong>{product.stock}</strong>
                            ) : (
                              <strong style={{ color: "red" }}>
                                Stokta Yok
                              </strong>
                            )}
                          </p>
                        </div>
                        <div className='card-footer bg-transparent d-flex justify-content-between'>
                          <button
                            className='btn btn-outline-danger'
                            onClick={() => handleDeleteProduct(product.id)}
                          >
                            Sil
                          </button>
                          <button
                            className='btn btn-outline-primary'
                            onClick={() =>
                              setModalState({
                                isOpen: true,
                                mode: "update",
                                product,
                              })
                            }
                          >
                            Güncelle
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <Pagination className='justify-content-center  m-0'>
              <Pagination.First
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => setCurrentPage((page) => Math.max(page - 1, 1))}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page + 1 === currentPage}
                  onClick={() => setCurrentPage(page + 1)}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() =>
                  setCurrentPage((page) => Math.min(page + 1, totalPages))
                }
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => setCurrentPage(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>

            {modalState.isOpen && (
              <Modal
                isOpen={modalState.isOpen}
                selectedProduct={modalState.product}
                mode={modalState.mode}
                onClose={() =>
                  setModalState({ isOpen: false, mode: "", product: null })
                }
                onSave={handleModalSave}
              />
            )}

            <ToastContainer
              position='bottom-right'
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Products;
