// Sidebar.js
import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Sidebar.scss";

const Sidebar = () => {
  return (
    <div className='sidebar'>
      <h3 className=' text-white position-absolute top-0 mt-3'>
        Yönetici Kontrol Paneli
      </h3>

      <NavLink to='/dashboard/products'>Ürünler</NavLink>
      <NavLink to='/dashboard/orders'>Siparişler</NavLink>
    </div>
  );
};

export default Sidebar;
