import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Function to fetch orders from the API
export const fetchOrders = async (page = 1, perPage = 20) => {
  try {
    const response = await axios.get(
      `${API_URL}?page=${page}&perPage=${perPage}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching orders");
  }
};
