import React, { useEffect, useState } from "react";

const UpdateModal = ({ onSave, onClose, selectedProduct }) => {
  const [productToUpdate, setProductToUpdate] = useState({
    id: selectedProduct.id,
    productName: selectedProduct.productName,
    price: selectedProduct.price,
    productDescription: selectedProduct.productDescription,
    skinType: selectedProduct.skinType,
    stock: selectedProduct.stock,
  });

  useEffect(() => {
    setProductToUpdate({
      id: selectedProduct.id,
      productName: selectedProduct.productName,
      price: selectedProduct.price,
      productDescription: selectedProduct.productDescription,
      skinType: selectedProduct.skinType,
      stock: selectedProduct.stock,
    });
  }, [selectedProduct]);

  const submitHandler = (e) => {
    e.preventDefault();
    onSave(productToUpdate);
    onClose();
  };

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className='form-group m-3'>
          <label>Ürün Adı</label>
          <input
            className='form-control'
            type='text'
            placeholder='Product Name'
            value={productToUpdate.productName}
            onChange={(e) =>
              setProductToUpdate({
                ...productToUpdate,
                productName: e.target.value,
              })
            }
          />
        </div>
        <div className='form-group m-3'>
          <label>Fiyat</label>
          <input
            className='form-control'
            type='number'
            placeholder='Price'
            value={productToUpdate.price}
            onChange={(e) =>
              setProductToUpdate({
                ...productToUpdate,
                price: Number(e.target.value),
              })
            }
          />
        </div>
        <div className='form-group  m-3'>
          <label>Ürün Açıklaması</label>
          <textarea
            className='form-control'
            style={{ height: "150px" }}
            placeholder='Product Description'
            value={productToUpdate.productDescription}
            onChange={(e) =>
              setProductToUpdate({
                ...productToUpdate,
                productDescription: e.target.value,
              })
            }
          />
        </div>
        <div className='form-group m-3'>
          <label>Cilt Tipi</label>
          <select
            className='form-control'
            value={productToUpdate.skinType}
            onChange={(e) => {
              setProductToUpdate({
                ...productToUpdate,
                skinType: e.target.value,
              });
            }}
          >
            <option value='normal'>Normal</option>
            <option value='dry'>Dry</option>
            <option value='oily'>Oily</option>
            <option value='combination'>Combination</option>
          </select>
        </div>
        <div className='form-group m-3'>
          <label>Stok</label>
          <input
            className='form-control'
            type='number'
            placeholder='Stock'
            value={productToUpdate.stock}
            onChange={(e) =>
              setProductToUpdate({
                ...productToUpdate,
                stock: Number(e.target.value),
              })
            }
          />
        </div>
        <button type='submit' className='btn btn-primary m-3'>
          Save
        </button>
      </form>
    </div>
  );
};

export default UpdateModal;
