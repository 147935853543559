// DashboardPage.js
import React, { useEffect } from "react";
import Sidebar from "../components/Layout/Sidebar/Sidebar";
import { useDispatch } from "react-redux";
import { authActions } from "../shared/store/slices/auth-slice";
import Cookies from "js-cookie";
import Navbar from "../components/Layout/Navbar/Navbar";
import { useNavigate, Outlet } from "react-router-dom";
const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("user");
    const token = Cookies.get("access_token");
    if (!user || !token) {
      navigate("/login");
    } else {
      dispatch(authActions.signInSuccess(JSON.parse(user)));
    }
  }, [dispatch, navigate]);

  return (
    <div>
      <Sidebar />
      <div
        style={{
          marginLeft: "300px",
        }}
      >
        <Navbar />
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardPage;
